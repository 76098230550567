// Branding styling

// Font weights
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-weight-light: 300;
$font-weight-thin: 100;

// Primary colors
$color-primary: #75c9bc;

// Font size
$font-xs: 0.625rem;
$font-sm: 0.75rem;
$font-md: 0.875rem;
$font-lg: 1rem;
$font-xl: 1.125rem;
$font-2xl: 1.25rem;
$font-3xl: 1.5rem;
$font-4xl: 1.875rem;
$font-5xl: 2.8rem;
$font-6xl: 3rem;
$font-7xl: 4rem;
$font-8xl: 5rem;
$font-9xl: 6rem;
$font-10xl: 7rem;

// Spacing
$spacing-0: 0;
$spacing-1: 0.25rem;
$spacing-2: 0.5rem;
$spacing-3: 0.75rem;
$spacing-4: 1rem;
$spacing-5: 1.25rem;
$spacing-6: 1.5rem;
$spacing-7: 1.75rem;
$spacing-8: 2rem;
$spacing-10: 2.5rem;
$spacing-12: 3rem;
$spacing-16: 4rem;
$spacing-20: 5rem;
$spacing-24: 6rem;
$spacing-32: 8rem;
$spacing-40: 10rem;
$spacing-48: 12rem;
$spacing-56: 14rem;
$spacing-64: 16rem;
$spacing-72: 18rem;
$spacing-80: 20rem;
$spacing-88: 22rem;
