@import './styles/_theme.module.scss';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,700;1,100&display=swap');

* {
  box-sizing: border-box;
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: $font-md;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: $color-primary;
}

h1 {
  font-weight: bold;
  font-size: $font-3xl;
}

h2 {
  font-size: $font-2xl;
}

h3 {
  font-size: $font-xl;
  margin-bottom: 0px;
}

h4 {
  font-size: $font-md;
  margin-bottom: 0.25rem;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

hr {
  width: 100%;
  margin-top: $spacing-6;
  border-top: 1px solid grey;
}

:focus,
:active {
  outline: none;
}